<template>
  <div class="confirm">
    <div class="box col-pc-4 col-smobile-12 uk-margin-auto">
      <div class="middle col-pc-11 col-smobile-12 col-mobile-10 col-margin-auto">
        <div class="top col-f">
          <div class="logout col-pc-3 col-f col-jfy-center" style="text-align: left;">
            <a @click="logout">{{ $t('actions.disconnect') }}</a>
          </div>
          <div class="logo col-pc-6">
            <img
              class="uk-margin-small-top uk-margin-small-bottom"
              src="//cdn.leafcad.com/images/logos/leafcad-logos/Logo%20LeafCAD.png"
              style="max-width: 120px;"
              alt="logo"
            />
          </div>
          <div class="empty col-pc-3"></div>
        </div>
        <div class="form col-pc-12 col-mobile-12">
          <h2>{{ $t('email-confirmation.account-confirmation') }}</h2>
          <alerts-display v-if="!$store.getters['auth/isConfirmed']" :errors="errors" />
          <div
            class="sent-to"
            v-if="$store.state.auth.user"
            v-show="!isChangingEmail && !$store.getters['auth/isConfirmed']"
          >
            <div class="text">
              <p class="primary">{{ $t('email-confirmation.before-proceeding-confirm-email') }}</p>
              <p class="primary">
                {{ $t('email-confirmation.email-sent-to') }}
                <span>{{ $store.state.auth.user.email }}</span>
              </p>
            </div>
            <div class="buttons">
              <button
                class="primary-full col-all-12 col-mdmobile-8 col-smobile-8"
                @click="resendEmail()"
                :disabled="isResendDisabled() || isLoading"
                :class="{ disabled: isLoading, spinner: isLoading }"
              >
                {{
                  isResendDisabled()
                    ? $t('email-confirmation.please-wait-and-retry', { time: timeLeft })
                    : $t('email-confirmation.resend-email')
                }}
              </button>
              <button class="primary col-all-12 col-mdmobile-8 col-smobile-8" @click="isChangingEmail = true">
                {{ $t('email-confirmation.change-email-address') }}
              </button>
            </div>
          </div>
          <div class="change-email" v-show="isChangingEmail && !$store.getters['auth/isConfirmed']">
            <div class="form email">
              <CustomInput
                class="custom-input primary col-all-12"
                required="required"
                type="email"
                icon="mail"
                :placeholder="$t('email-confirmation.email')"
                v-model.trim="$v.newEmail.$model"
                :model="$v.newEmail"
                :error="fieldsErrors.newEmail"
              ></CustomInput>
              <p v-if="errors.email" class="orange error">
                <span class="orange" uk-icon="icon: warning"></span>
                {{ errors.email }}
              </p>
            </div>
            <div class="buttons">
              <button
                class="primary col-all-6 col-mdmobile-8 col-smobile-8"
                @click="
                  isChangingEmail = false
                  errors = []
                  newEmail = ''
                  fieldsErrors.newEmail = false
                "
              >
                {{ $t('email-confirmation.cancel') }}
              </button>
              <button
                class="primary-full col-all-6 col-mdmobile-8 col-smobile-8"
                @click="changeEmail()"
                :disabled="isResendDisabled() || isLoading"
                :class="{ disabled: isLoading, spinner: isLoading }"
              >
                {{
                  isResendDisabled()
                    ? $t('email-confirmation.please-wait-and-retry', { time: timeLeft })
                    : $t('email-confirmation.change-email-address')
                }}
              </button>
            </div>
          </div>
          <div class="confirmed" v-if="$store.getters['auth/isConfirmed']">
            <p>{{ $t('email-confirmation.account-confirmed') }}</p>
            <div class="buttons">
              <button class="primary-full col-all-6 col-mdmobile-8 col-smobile-8" @click="verifiedDone()">
                {{ $t('actions.continue') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="right col-pc-3"></div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/login/confirm.less"></style>
<style lang="less" scoped src="@/assets/less/login/register.less"></style>

<script>
import validationMessage from '@/validationMapping'
import { required, email } from 'vuelidate/lib/validators'
import CustomInput from '@/components/CustomInput'

export default {
  name: 'confirm',
  components: {
    CustomInput
  },
  data: function() {
    return {
      errors: [],
      fieldsErrors: {},
      isLoading: false,
      newEmail: '',
      isChangingEmail: false,
      timeToWait: ''
    }
  },
  validations: {
    newEmail: {
      required,
      email
    }
  },
  methods: {
    waitTime: function() {
      const interval = setInterval(() => {
        let nextTime = this.moment(this.$store.state.auth.emailCooldown).add(1, 'minutes')
        let timeToWait = this.moment.duration(nextTime.diff(this.moment()))
        if (nextTime.diff(this.moment()) <= 0) {
          clearInterval(interval)
          this.$store.dispatch('auth/emailCooldownFinished')
        }
        this.timeToWait =
          timeToWait.minutes() + ':' + (timeToWait.seconds() < 10 ? '0' + timeToWait.seconds() : timeToWait.seconds())
      }, 1000)
    },
    isResendDisabled: function() {
      if (this.$store.state.auth.emailCooldown) {
        if (
          this.moment(this.$store.state.auth.emailCooldown)
            .add(1, 'minutes')
            .diff(this.moment()) > 0
        ) {
          this.waitTime()
          return true
        }

        return false
      }

      return false
    },
    changeEmail: function() {
      this.isLoading = true

      if (this.$v.$invalid) {
        this.errors = ['FIX_ERRORS']
        this.fieldsErrors = {
          newEmail: validationMessage(this.$v.newEmail)
        }
        this.isLoading = false
        return
      }

      this.$store
        .dispatch('auth/updateUser', { email: this.newEmail })
        .then(response => {
          this.isLoading = false

          this.newEmail = ''
          this.isChangingEmail = false
        })
        .catch(error => {
          this.isLoading = false

          error.graphQLErrors.map(({ extensions }) => {
            this.errors = Object.values(extensions)

            if (this.errors.includes('EMAIL_ALREADY_USED')) {
              this.fieldsErrors.newEmail = this.$t('errors.EMAIL_ALREADY_USED')
            } else {
              this.fieldsErrors = {
                newEmail: true
              }
            }
          })
        })
    },
    resendEmail: function() {
      this.errors = []

      this.$store
        .dispatch('auth/resendVerificationEmail')
        .then(response => {
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false

          error.graphQLErrors.map(({ extensions }) => {
            this.errors = Object.values(extensions)
          })
        })
    },
    verifiedDone: function() {
      if (this.$store.getters['auth/isConfirmed']) {
        this.$router.push({ name: 'communities' })
      }
    },
    logout: function() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  },
  computed: {
    timeLeft: function() {
      return this.timeToWait
    }
  },
  mounted() {
    if (this.$route.params.token) {
      this.$store.dispatch('auth/verify', this.$route.params.token).catch(error => {
        console.error(error)

        error.graphQLErrors.map(({ extensions }) => {
          this.errors = Object.values(extensions)
        })
      })
    }

    if (this.isResendDisabled()) {
      this.waitTime()
    }
  }
}
</script>
